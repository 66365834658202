import Vue from 'vue';

const useCanonicalHead = (routeGetter = (router) => router.currentRoute.path) => Vue.extend({
  head() {
    const canonicalUrl = new URL(routeGetter(this.$router), process.env.NUXT_ENV_HOSTNAME);
    // NOTE: ensure protocol
    canonicalUrl.protocol = 'https';
    return {
      link: [
        { rel: 'canonical', href: canonicalUrl.href },
      ],
    };
  },
});

export default useCanonicalHead;
