export const HomePageEvent = {
  HeroButtonClick: 'Homepage Hero_Button click',
  BuyYourHomeLink: 'Homepage Hero_Left hyperlink click',
  SellYourHomeLink: 'Homepage Hero_Middle hyperlink click',
  GetSoldDataLink: 'Homepage Hero_Right hyperlink click',
  BuySellButton: 'Homepage_Buy_Sell button click',
  HomeDataCard: 'Homepage_HomeData link click',
  ProMarketplaceCard: 'Homepage_ProMarketplace link click',
  BodeSupportCard: 'Homepage_BodeSupport link click',
  TestimonyCarousel: 'Homepage_Testimony slider control click',
  FeaturedInCarousel: 'Homepage_FeaturedIn slider control click',
  SignUpButton: 'Homepage_Signup button click',
  AboutTheseStatsLink: 'Homepage_About these stats hyperlink click',
};

export const BuyYourHomePageEvent = {
  HeroButtonClick: 'Buy your home_2_Hero button click',
  StatsLink: 'Buy your home_Proof page link click',
  FAQScroll: 'Buy your home_FAQ section scroll',
  GetStartedFooter: 'Buy your home_Bottom ',
};

export const ProEditEvent = {
  EditServiceDetails: 'Pro Profile_Edit Service Details click',
  EditBusinessDetails: 'Pro Profile_Edit business details click',
  NewServiceStarted: 'Pro Profile_New service started',
  ServiceDetailsPublished: 'Pro Profile_Service details published',
  ServiceDetailsUnPublished: 'Pro Profile_Service details unpublished',
};

export const ProEditProfileEvent = {
  SubmitProfile: 'Pro Profile_Business details_Save + Continue button click',
  NameAdded: 'Pro Profile_Business name added',
  LogoDeleted: 'Pro Profile_Business logo deleted',
  LogoAdded: 'Pro Profile_Business logo added',
  WebsiteAdded: 'Pro Profile_Business website added',
  DescriptionAdded: 'Pro Profile_Business description added',
};

export const ProEditServiceEvent = {
  DescriptionAdded: 'Pro Profile_Service details_Service description added',
  CategoryAdded: 'Pro Profile_Service details_Category added',
  ServiceAdded: 'Pro Profile_Service details_Service added',
  PhotoDeleted: 'Pro Profile_Service details_Photo deleted',
  PhotoUploaded: 'Pro Profile_Service details_Photo uploaded',
  PriceAdded: 'Pro Profile_Service details_Price added',
  ChargeTypeAdded: 'Pro Profile_Service details_Charge type added',
  PublishChecked: 'Pro Profile_Service details_Publish immediately box checked',
  TermsChecked: 'Pro Profile_Service details_T&C box checked',
  ServiceAgreementClick: 'Pro Profile_Service details_View my service agreement clicked',
  SaveButtonClick: 'Pro Profile_Service details_Save + Preview Button click',
};

export const SellYourHomePageEvent = {
  HeroButtonClick: 'Sell your home_3_Hero button click',
  StatsLink: 'Sell your home_Proof page link click',
  FAQScroll: 'Sell your home_FAQ section scroll',
  ProvinceSelected: 'Sell your home_Commission Calculator Province selected',
  GetStartedFooter: 'Sell your home_Bottom ',
};

export const BuySellYourHomePageEvent = {
  HeroButtonClick: 'Buy_Sell Home_2_Hero button click',
  StatsLink: 'Buy_Sell_Proof points hyperlink click',
  FooterButton: 'Buy_Sell_',
};

export const NavigationEvent = {
  LogIn: 'Navigation_Log in button click',
  SignUp: 'Navigation_Sign up button click',
};

export const ListingDashboardEvent = {
  DeleteSoldComparable: 'Listing Dashboard_Sold Comparables_Listing deleted',
  DeleteActiveComparable: 'Listing Dashboard_Active Comparables_Listings deleted',
  AddToFavouriteActiveComparable: 'Listing Dashboard_Active Comparables_Listing favourited',
  RemoveFromFavouriteActiveComparable: 'Listing Dashboard_Active Comparables_Listing not favourited',
  ViewActiveComparable: 'Listing Dashboard_Active Comparables_View Listing button click',
};

export const ListingPageEvent = {
  BodeComparable: 'Bode_Comparable homes clicked',
  BodeSellerSuccessPage: 'Bode_Message sent to seller via success page',
  CommunityLink: 'Community link click',
  DraftListingDeleted: 'Draft listing deleted',
  ViewListingClick: 'View listings button click',
  SoldDataLink: 'Sold Data link click',
  MLSComparable: 'MLS_Comparable homes clicked',
  PhotosViewed: 'Photos viewed',
  VideoViewed: 'Video viewed',
  ThreeDTourViewed: '3D tour viewed',
  AddedToFavourites: 'Listing added to favourites',
  RemovedFromFavourites: 'Listing removed from favourites',
  ViewMyListing: 'My Listings_View listing button click',
  EditMyListing: 'My Listings_Edit listing icon click',
  SellYourHome: 'My Listings_Sell your home button click',
  TermsCheckboxChecked: 'Listing flow_Terms + Conditions box checked',
  TermsCheckboxUnchecked: 'Listing flow_Terms + Conditions box unchecked',
  EditListing: 'Listing flow_Edit Listing button click',
  SubmitListing: 'Listing flow_Submit Listing button click',
  LearnHowItWorksClicked: 'Learn how bode works button click',
  TrilogyClicked: 'Trilogy button click',
  AgentOfferStarted: 'Bode_Offer started_Agent',
  BuyerOfferStarted: 'Bode_Offer started_Buyer',
  MLSOfferStarted: 'MLS_Offer started',
  MessageSentToSeller: 'Bode_Message sent to seller',
  RequestInfoClicked: 'Request info button clicked',
  CancelOfferClicked: 'Bode_Offer cancel button click',
  BookViewingClickedAuthenticated: 'Book a viewing_User authenticated',
  BookViewingClickedUnauthenticated: 'Book a viewing_User unauthenticated',
  CancelBookViewingClicked: 'MLS_Viewing request_Cancel button clicked',
  BookViewingDateSelected: 'Bode_Book a viewing_Date selected',
  BookViewingTimeSelected: 'Bode_Book a viewing_Time selected',
  MLSContactLinkClicked: 'MLS_Offer instructions link clicked',
  BodeSellerProfileClicked: 'Bode_Seller profile link click',
  BuilderListingReassignmentClick: 'My Listings_Builder draft reassignment clicked',
};

export const ListingBookViewingEvent = {
  MLSViewingRequestDate: 'MLS_Viewing request_Date added',
  MLSViewingRequestStartTime: 'MLS_Viewing request_Start time added',
  MLSViewingRequestDuration: 'MLS_Viewing request_Duration added',
  MLSViewingRequestAdditionalTimeLink: 'MLS_Viewing request_Additional time link clicked',
  MLSViewingRequestRemoveDataLink: 'MLS_Viewing request_Remove data link clicked',
  MLSViewingRequestRequestPhoneNumber: 'MLS_Viewing request_Phone number added',
  MLSViewingRequestRequestEmail: 'MLS_Viewing request_Email updated',
  MLSViewingRequestContact: 'MLS_Viewing request_Contact method selected',
  ViewingRequestCompleted: 'Viewing request completed',
};

export const SearchPageEvent = {
  ViewListing: 'Search Listings_View listing selected',
};

export const NotesModalEvent = {
  ButtonClick: 'My notes save button click',
};

export const MyPicturesModalEvent = {
  ButtonSaveClick: 'Favourites_My pictures save button click',
  PictureUpload: 'Favourites_Picture uploaded',
};

export const ComparablesEvent = {
  Apply: 'Comparables_Apply button clicked',
  LoadSoldListings: 'Comparables_Load more sold listings click',
  LoadLiveListings: 'Comparables_Load more live listings click',
  ResetClick: 'Comparables_Reset button clicked',
  AddressEntered: 'Comparables_Address entered',
  AddressLinkClick: 'Comparables_Address link clicked',
  PropertyTypeFilterSelected: 'Comparables_Property type filter selected',
  PropertyStyleSelected: 'Comparables_Property style selected',
  YearBuiltSelected: 'Comparables_Year Built filter selected',
  SqftSelected: 'Comparables_Sqft filter selected',
  ParkingSelected: 'Comparables_Number of parking spots filter selected',
  BedsSelected: 'Comparables_Number of Beds filter selected',
  BathsSelected: 'Comparables_Number of Baths filter selected',
  ListYourHome: 'Comparables_List your home button click',
  ExploreMarketData: 'Comparables_Explore Market Data button click',
  ContactBode: 'Comparables_Contact Bōde team button click',
};

export const ProMarketplaceLandingPageEvent = {
  LearnMoreButton: 'Prō for Every Need_Learn more button click',
  FindAProButton: 'Prō for Every Need_Find a Prō button click',
};

export const MarketplaceForProsEvent = {
  HeroApplyNow: 'Marketplace for Prōs_Apply now hero button click',
  DescriptionApplyNow: 'Marketplace for Prōs_Apply now second section button click',
  FooterApplyNow: 'Marketplace for Prōs_Apply now bottom section button click',
};

export const SavedSearchEvent = {
  SavedSearchDelete: 'Saved Search_Delete search confirmed',
  SavedSearchEdit: 'Saved Search_Edit search clicked',
  SavedSearchViewListings: 'Saved Search_View listings clicked',
  SavedSearchListingsClick: 'Saved Search_Search listings clicked',
};

export const SearchFiltersEvent = {
  SavedSearchStartButton: 'Search Listings_Saved search start button clicked',
  BodeListingsSelected: 'Search Listings_Listing Type filter selected',
  BodeListingsUnselected: 'Search Listings_Listing Type filter unselected',
  SoldListingsSelected: 'Search Listings_Hide Bode sold listings filter selected',
  SoldListingsUnselected: 'Search Listings_Hide Bode sold listings filter unselected',
  PropertyTypeSelected: 'Search Listings_Property type filter selected',
  PropertyTypeUnselected: 'Search Listings_Property type filter unselected',
  ListingFiltersReset: 'Search Listings_Search filters reset',
  YearBuiltSelected: 'Search Listings_Year built filter selected',
  YearBuiltUnselected: 'Search Listings_Year built filter unselected',
  FinishedAreaSelected: 'Search Listings_Sqft finished area filter selected',
  FinishedAreaUnselected: 'Search Listings_Sqft finished area filter unselected',
  LandSizeSelected: 'Search Listings_Land size filter selected',
  LandSizeUnselected: 'Search Listings_Land size filter unselected',
  BedsSelected: 'Search Listings_Beds filter selected',
  BathsSelected: 'Search Listings_Baths filter selected',
  PriceSelected: 'Search Listings_Minimum price filter selected',
};

export const SoldDataEvent = {
  ComparableClick: 'Sold Data_Check Comparables button click',
  ShareClick: 'Sold Data_Share search start button click',
  ContactBodeClick: 'Sold Data_Contact Bode team button click',
  AddressEntered: 'Sold Data_address entered',
  ShareSearchSendClick: 'Sold Data_Share search send button click',
  AddressLinkClick: 'Sold Data_Address link clicked',
  FiltersApplyClick: 'Sold Data_Apply button clicked',
  FiltersResetClick: 'Sold Data_Reset button clicked',
  SearchApplyClick: 'Sold Data_Address search triggered',
  AddressSelect: 'Sold Data_address entered',
  ProvinceSelect: 'Sold Data_province selected',
  CitySelect: 'Sold Data_city selected',
  CommunitySelect: 'Sold Data_community selected',
  DatePeriodSelect: 'Sold Data_Filter period selected',
  MapPinClick: 'Sold Data_Map pin click',
  MapSoldPropertyCardClick: 'Sold Data_Sold Property Page loaded',
};

export const AuthSignUpTrackEvent = {
  NewUserSubmitted: 'New user_sign up submitted',
  FormSubmissionError: 'New user_sign up error',
};

export const AuthSignUpConfirmTrackEvent = {
  ConfirmationCodeDoneSuccessfully: 'New user_Email verification code submitted',
  ConfirmationCodeFailed: 'New user_sign up confirm error',
  ResendVerificationCodeDone: 'New user_Resend verification code requested',
  ResendVerificationCodeFailed: 'sign up verification code failed',
};

export const AuthLoginTrackEvent = {
  SubmitLogInButton: 'Returning user_log in submitted',
  SuccessfulLogIn: 'Returning user log in',
  LogInError: 'Returning user_log in error',
  NeedToConfirmUser: 'Returning user_verification code requested',
  ConfirmUserError: 'Returning user_confirmation error',
};

export const AuthResetPasswordTrackEvent = {
  ResetCodeSent: 'We sent a new code at',
  ResetCodeSendError: 'Reset password_send email error',
  ResetEmailStepSubmitted: 'Reset password_send email submitted',
  ResendRestCodeRequested: 'Reset password_resend verification code requested',
  ResetPasswordBStepButtonClicked: 'Reset password_button click',
  ResetPasswordSubmitError: 'Reset password error',
};

export const AuthAdditionalInformation = {
  PromotionalChecked: 'New user_Promotional emails opt in checked',
  PromotionalUnChecked: 'New user_Promotional emails opt out checked',
  AdditionalInformationSubmitted: 'New user_Additional information submitted',
  SkipButtonClick: 'New user_Additional information skipped',
};

export const ProRequestEvent = {
  PhoneNumberAdded: 'Phone Number added',
  UnitAdded: 'Unit added',
  AddressAdded: 'Address added',
  CityAdded: 'City added',
  ProvinceAdded: 'Province added',
  PostalCodeAdded: 'Postal Code added',
  PropertyTypeAdded: 'Property type added',
  InteriorSqftAdded: 'Interior sqft added',
  NameAdded: 'Name added',
  DescriptionAdded: 'Description added',
  BudgetAdded: 'Budget added',
  NumberOfBedroomsAdded: 'Number of bedrooms added',
  NumberOfFullBathroomsAdded: 'Number of full bathrooms added',
  NumberOfHalsBathroomsAdded: 'Number of half bathrooms added',
  DateSelected: 'Date selected',
  TimespanSelected: 'Timespan selected',
  UnableToFindAddressLinkClick: 'Unable to find address link click',
  BackButtonClicked: 'Back button clicked',
  NextButtonClicked: 'Next button click',
  SubmitButtonClicked: 'Submit Request button clicked',
  RequestStarted: 'Request started',
};

export const WhyBodeTrackEvent = {
  HeroClick: 'Why Bode_Hero button click',
  BuyProperty: 'Why Bōde_Buy a property button click',
  SellProperty: 'Why Bōde_Sell a property button click',
};

export const WhoWeAreTrackEvent = {
  HeroClick: 'Who We Are_Hero button click',
};

export const MyAbodeOverviewTrackEvent = {
  MarketDataClick: 'My Abode Overview_Market data button click',
  SoldDataClick: 'My Abode Overview_Sold data button click',
  ComparableClick: 'My Abode Overview_Comparable button click',
};

export const AccountTrackEvent = {
  GetVerified: 'Get verified button click',
};

export const FooterTrackEvent = {
  PrivacyPolicy: 'Footer_Privacy policy link click',
  TermsOfService: 'Footer_Terms of service link click',
  InstagramIconClick: 'Footer_Instagram icon link click',
  FacebookIconClick: 'Footer_Facebook icon link click',
  LinkedInIconClick: 'Footer_LinkedIn icon link click',
  BodeLogoClick: 'Footer_Bode logo link click',
  ScheduleACallClick: 'Footer_Schedule a call button click',
  OfficeLinkClick: 'Footer_Office link click',
};

export const MarketDataEvent = {
  ApplyFiltersClick: 'Market Data_Apply Filters button clicked',
  CommunitySelected: 'Market Data_Community selected',
  CommunityUnselected: 'Market Data_Community unselected',
  ProvinceSelected: 'Market Data_Province Selected',
  CitySelected: 'Market Data_City selected',
  BedsSelected: 'Market Data_Number of beds filter selected',
  BedsUnselected: 'Market Data_Number of beds filter unselected',
  BathsSelected: 'Market Data_Number of baths filter selected',
  BathsUnselected: 'Market Data_Number of baths filter unselected',
  DateRangeSelected: 'Market Data_Date range selected',
  PropertyTypeSelected: 'Market Data_Property type filter selected',
  PropertyTypeUnselected: 'Market Data_Property type filter unselected',
  YearBuiltSelected: 'Market Data_Year Built filter selected',
  MaxPriceSelected: 'Market Data_Maximum price filter selected',
  MinPriceSelected: 'Market Data_Minimum price filter selected',
  GenerateGraphClick: 'Market Data_Generate Graph button clicked',
  BrowseSoldData: 'Market Data_Browse Sold Data button click',
  ShareSearchSendClick: 'Market Data_Share chart by link button click',
};

export const ProServiceRequestEvent = {
  LeadLinkClick: 'Service Requests Overview_lead link click',
};

export const ProViewRequestEvent = {
  AcceptLead: 'Lead details_Accept lead click',
  DeclineLead: 'Lead details_Decline lead click',
};

export const HowItWorksBuilderEvent = {
  HeroClick: 'How it works_builders_Hero button click',
};

export const HowItWorksSellerEvent = {
  HeroClick: 'How it works_sellers_Hero button click',
};

export const HowItWorksBuyerEvent = {
  HeroClick: 'How it works_buyers_Hero button click',
};

export const SoldPropertyPageEvent = {
  SellNowClick: 'Sold property_Sell now button click',
  SellInThreeMonthsClick: 'Sold property_Sell in 3 months button click',
  NoPlansToSellClick: 'Sold property_No plans to sell button click',
};

export const HomeownerDashboardPageEvent = {
  StartListingClick: 'Homeowner Dashboard_Start Listing button click',
};
