export const StyleColorSchemeType = {
  Primary: 'primary',
  PrimaryLight: 'primary-light',
  Secondary: 'secondary',
  SecondaryLight: 'secondary-light',
  SecondaryLighter: 'secondary-lighter',
  GrayLight: 'gray-light',
  GraySemiLight: 'gray-semi-light',
  GrayLightest: 'gray-lightest',
  Gray: 'gray',
  GrayDark: 'gray-dark',
  Success: 'success',
  SuccessDark: 'success-dark',
  Danger: 'danger',
  Black: 'black',
  White: 'white',
  Info: 'info',
};

export enum StyleSizeType {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
  XXLarge = 'xxlarge',
}

export const StyleBemType = {
  Block: 'block',
  Element: 'element',
  Modifier: 'modifier',
};

export const StylePositionType = {
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left',
};

export const StyleAlignmentType = {
  Left: 'left',
  Center: 'center',
  Right: 'right',
};

export const StyleOverflowType = {
  Scroll: 'scroll',
  Auto: 'auto',
};

export const StyleFontWeightType = {
  Light: 'light',
  Normal: 'normal',
  SemiBold: 'semi-bold',
  Bold: 'bold',
};

export const StyleOrientationType = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
